<template>
	<div class="box-page" v-if="total > 1">
		<ul class="page">
			<li class="text" @click="$emit('fn',pg.first)"><a><em>«</em><i>首页</i></a></li>
			<li class="text" @click="$emit('fn',pg.prev)"><a><em>‹</em><i>上一页</i></a></li>
			<template v-for="(vo,idx) in pg.list" :key="idx">
				<li class="active" v-if="vo == page"> <a>{{page}}</a> </li>
				<li :class="{'near':pg.show.indexOf(vo) != -1}" @click="$emit('fn',vo)" v-else> <a>{{vo}}</a> </li>
			</template>
			<li class="text" @click="$emit('fn',pg.next)"><a><em>›</em><i>下一页</i></a></li>
			<li class="text" @click="$emit('fn',pg.last)"><a><em>»</em><i>尾页</i></a></li>
		</ul>
	</div>
</template>
<script setup>
import { computed,reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick,defineProps } from "vue";
import { useStore } from "vuex";
import { useRouter,useRoute } from "vue-router";
const currentInstance = getCurrentInstance()
const { $ajax } = currentInstance.appContext.config.globalProperties
const $router = useRouter();
const $route = useRoute()
const store = useStore();

// data
// const data = reactive({})
// const {listData,classInfo} = toRefs(data)
const props = defineProps({
    // text:String,
    // message:Number
    total:{
		// type:Number,
		default:function() {
			return 0
		}
	},
	page:{
		// type:Number,
		default:function() {
			return 1
		}
	},
	limit:{
		// type:Number,
		default:function() {
			return 0
		}
	}
})

let pg = computed(function(){
	let pages  = Number(props.page || 1)
	let start = pages - 2;
	let end   = pages + 2;
	let max = Math.floor(props.total / props.limit)
	
	if(end < 5){
		end = 5
	}
	if(max < 5){
		end = max
	}else if(end > max) end = max;

	if(start > (max - 5)){
		start = max - 5
	}
	if(start < 1) start = 1;

	let res = []
	for(let i=start;i<=end;i++){
		res.push(i)
	}


	let resp = {
		first:1,
		prev:(pages - 1) > 0 ? (pages - 1):1,
		list:res,
		next:(pages + 1) <= max ? (pages + 1):max,
		last:max,
		show:[],
	}
	let show = []
	if(pages == 1){
		show = [1,2,3]
	}else if(pages == max){
		show = [max-2,max-1,max]
	}else{
		show = [resp.prev,pages,resp.next]
	}
	resp.show = show
	return resp
})
</script>

<style scoped>
.box-page {margin: 1rem 0;text-align: center}
.box-page ul.page {text-align: center;padding: 1rem 0;}
.box-page ul.page li {cursor:pointer;display: inline-block;padding: 0;margin:0;width:auto;height: auto;border: 1px solid var(--font);border-radius: 0.1rem;margin:0 0.2rem;float: none;}
.box-page ul.page li:hover{opacity:0.9;background-color: var(--font);}
.box-page ul.page li:hover a{color:var(--theme);}
.box-page ul.page li span,
.box-page ul.page li a {display: inline-block;padding: 0 1rem;border-radius: 0.1rem;height: 2rem !important;line-height: 2rem  !important;}
.box-page ul.page li.active{background-color: var(--font);}
.box-page ul.page li.active a{color:var(--theme);}
.box-page ul.page li a *{font-style: normal;}
.box-page ul.page li a em{display: none;}
@media screen and (max-width: 550px) {
	.box-page ul.page li a i{display: none;}
	.box-page ul.page li a em{display: inline;}
	.box-page {margin:0;padding:0 !important;}
	.box-page ul.page li {display: none;}
	.box-page ul.page li.active,
	.box-page ul.page li.near,
	.box-page ul.page li.text{display: inline-block;}
}
</style>