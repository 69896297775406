import { createStore } from "vuex";

export default createStore({
	state: {
		dayStart:new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime() / 1000, // 当天零点
		loading:false, // 加载中
		pageKey:10000, // 路由key
		siteInfo:{}, // 站点信息
		domain:"",
		num:{}, // 数据数量
		sitePub:{}, // 广告数据
		siteClass:[], // 分类数据
		vodClass:[], // 分类数据
		txtClass:[], // 分类数据
		picClass:[], // 分类数据
	},
	getters: {},
	mutations: {
		SiteInfo(state, info) {
			state.siteInfo = info || {};
			state.num      = info.tplDataNums || {};
			state.domain   = info.domain || location.hostname;
		},
		SitePub(state, info) {
			state.sitePub = info || {};
		},
		SiteClass(state, list) {
			list         = list || []
			let vodClass = []
			let txtClass = []
			let picClass = []
			list.forEach(function(item){
				switch(item.type){
					case 1:
						vodClass.push(item)
						break;
					case 2:
						picClass.push(item)
						break;
					case 3:
						txtClass.push(item)
						break;
					default:
				}
			})
			state.siteClass = list;
			state.vodClass  = vodClass;
			state.txtClass  = txtClass;
			state.picClass  = picClass;
		},
		Loading(state, val) {
            state.loading = val || false;
        },
        PageKey(state, sec) {
            state.pageKey = Math.random() * 1000;
        },
	},
	actions: {
	},
})