<template>
  <router-view :key="$store.state.pageKey"></router-view>
</template>
<script setup>
	import { reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
	
	import { useStore } from "vuex";
	const store = useStore();

	//获取上下文
	const currentInstance = getCurrentInstance()
	const { $ajax } = currentInstance.appContext.config.globalProperties
	
	// api
	import useApi from  '@/lib/js/api.js'
	let api = useApi($ajax)

	onMounted(() => {
		api.getClassList({}).then(res=>{
			store.commit("SiteClass",res.data || [])
		})
	})

	import { useRouter } from "vue-router";
	let router = useRouter();

	watch(()=>router,(n,o)=>{
		store.commit("PageKey")
	},{immediate:true,deep:true})
</script>