import { createApp } from 'vue'
import App from '@/hxsp/App.vue'
import router from '@/hxsp/router'
import init from '@/lib/js/public.js'

import lazyPlugin from 'vue3-lazy'
import '@/hxsp/assets/css/main.css'
import '@/hxsp/assets/css/style.css'

const app = createApp(App)
import AppHeader from '@/hxsp/components/header.vue';
import AppFooter from '@/hxsp/components/footer.vue';
app.component("AppHeader",AppHeader);
app.component("AppFooter",AppFooter);

//按需进行请求挂载
init(app,{
    "protocol": "",
    "hostname": "",
    "port": 0
}).then(res=>{
    app.use(res.store).use(router(res.data || {},process.env.NODE_ENV=='development')).use(lazyPlugin, {
    }).mount('#app_hxsp')
}).catch(err=>{
    alert(err)
})