import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router';
export default function(siteInfo,isDev){
    let base      = siteInfo.previewPath || ""
    let homePage  = siteInfo.domainRule.contentHomePage || "/index.html"
    if(isDev){
        base = ""
        homePage = "index.html"
    }
    return createRouter({
        // history: isDev ? createWebHistory():createWebHashHistory(),
        history: createWebHistory(),
        routes:[
            {
                path: '/',
                name: 'App',
                component: () => import('@/hxsp/pages/layout.vue'),
                redirect: { name: 'Index' },
                children:[
                    {
                        path: homePage,
                        name: 'Index',
                        component: () => import('@/hxsp/pages/index.vue')
                    },
                    {
                        path: `${base}/picdetail/:id.html`,
                        name: 'picInfo',
                        component: () => import('@/hxsp/pages/picdetail.vue')
                    },
                    {
                        path: `${base}/piclist/:pcid.html`,
                        name: 'picList',
                        component: () => import('@/hxsp/pages/piclist.vue')
                    },
                    {
                        path: `${base}/stardetail/:id.html`,
                        name: 'starInfo',
                        component: () => import('@/hxsp/pages/stardetail.vue')
                    },
                    {
                        path: `${base}/starlist/:scid.html`,
                        name: 'starList',
                        component: () => import('@/hxsp/pages/starlist.vue')
                    },
                    {
                        path: `${base}/txtdetail/:id.html`,
                        name: 'txtInfo',
                        component: () => import('@/hxsp/pages/txtdetail.vue')
                    },
                    {
                        path: `${base}/txtlist/:tcid.html`,
                        name: 'txtList',
                        component: () => import('@/hxsp/pages/txtlist.vue')
                    },
                    {
                        path: `${base}/voddetail/:id.html`,
                        name: 'vodInfo',
                        component: () => import('@/hxsp/pages/voddetail.vue')
                    },
                    {
                        path: `${base}/vodlist/:vcid.html`,
                        name: 'vodList',
                        component: () => import('@/hxsp/pages/vodlist.vue')
                    }
                ]
            }
        ]
    })
}