<template>
	<Position position="alltop" />
	 <div class="section-banner">
			<Position position="topbanner" />
	</div>

	<div class="clear"></div>
	<header>
		<div>易记域名: {{$store.state.domain}}</div>
		<div class="clear"></div>
	</header>
	<div class="menu clearfix">
		   <div class="jiugongad">
			   <Position position="jiugongad" />
		   </div>
		<Position position="head" />
		<dl v-if="store.state.vodClass.length > 0">
			<dt>
				<router-link target="_blank" :to="{name:'Index'}" class="now">电影</router-link>
			</dt>
			<template v-for="(sub,idx) in store.state.vodClass">
				<dd v-if="sub.status == 1">
					<router-link target="_blank" :to="{name:'vodList',params:{vcid:sub.id}}" :class="{'on':$route.params.vcid==sub.id}">{{sub.name}}</router-link>
				</dd>
			</template>
		</dl>

		<dl v-for="(item,index) in store.state.picClass">
			<dt>
				<router-link target="_blank" :to="{name:'Index'}" class="now">图片</router-link>
			</dt>
			<template v-for="(sub,idx) in item.children">
				<dd v-if="sub.status == 1">
					<router-link target="_blank" :to="{name:'picList',params:{pcid:sub.id}}" :class="{'on':$route.params.pcid==sub.id}">{{sub.name}}</router-link>
				</dd>
			</template>
		</dl>

		<dl v-for="(item,index) in store.state.txtClass">
			<dt>
				<router-link target="_blank" :to="{name:'Index'}" class="now">小说</router-link>
			</dt>
			<template v-for="(sub,idx) in item.children">
				<dd v-if="sub.status == 1">
					<router-link target="_blank" :to="{name:'txtList',params:{tcid:sub.id}}" :class="{'on':$route.params.tcid==sub.id}">{{sub.name}}</router-link>
				</dd>
			</template>
		</dl>
		<Position position="menudown" />
	</div>

	<Position position="mytop" />
</template>
<script setup>
import { reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick,watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const currentInstance = getCurrentInstance()
const { $ajax } = currentInstance.appContext.config.globalProperties
const router = useRouter();
const store = useStore();

// data
const data = reactive({
	classPic: {},
	classTxt: {},
	classVod: {},
})
const { classPic,classTxt,classVod } = toRefs(data)

// ok
onMounted(() => {
})
</script>