<template>
    <Position position="foot" />
    <footer>
        <b>
            站点申明：我们立足于美利坚合众国，受北美法律保护,未满18岁或被误导来到这里，请立即离开！
        </b>
    </footer>
    <Position position="float" />
    <Position position="bottomfloat" />
    <Position position="count" />
</template>